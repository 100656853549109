/**
 * This File is used to initialize RUM and is bundled separately
 * through Webpack.
 */

import { datadogRum } from '@datadog/browser-rum'
import getEnvInfo from './helpers/getEnvInfo'

const envInfo = getEnvInfo()

if (!APP_DEVELOPMENT) {
  datadogRum.init({
    applicationId: APP_DD_RUM.APPLICATION_ID,
    clientToken: APP_DD_RUM.CLIENT_TOKEN,
    site: APP_DD_RUM.SITE,
    service: APP_NAME,
    env: envInfo.env,
    version: APP_VERSION || '<UNDEFINED>',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask'
  })
}
